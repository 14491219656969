import { useRoute } from "@react-navigation/native";
import {
  Alert,
  ButtonBorder,
  Div,
  HeaderBack,
  ImageView,
  Label,
} from "components";
import { urlApp } from "constants/AppUrl";
import { Icons } from "constants/Icons";
import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { apiAuth } from "services/api";
import { navigate } from "services/navigation";
import {
  convertDaTaCCCD,
  convertDaTaNewCC,
} from "services/react_native_true_id";
import { useAppSelector } from "store/hooks";
import { Log, getStoreData, setStoreData, setStoreToken } from "utils";
import { PostMSG } from "utils/utils";

function ControlEKYCScreen() {
  const route = useRoute<any>();
  // const route = {
  //   params: {
  //     data: {
  //       confirmPassword: "Kien12",
  //       email: "kienn@gmail.com",
  //       name: "kienn",
  //       password: "Kien12",
  //       phone: "0352312322",
  //       phonePostal: "+84",
  //       provinceId: 7,
  //       userRefCode: null,
  //       username: "0352312322",
  //     },
  //   },
  // };
  const countTime = useRef<number>(1);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (route.params?.data) {
  //       getAccountToken();
  //     }
  //   }, 300);
  //   return () => {};
  // }, [route.params?.data]);

  useEffect(() => {
    importL();
    return () => {};
  }, []);

  const importL = () => {
    setLoading(true);
    setTimeout(() => {
      try {
        import("../assets/trueidsdk/trueid.js")
          .then((r) => {
            Log("success");
          })
          .catch((err) => {
            Log("errerrerrerr", err);
          });
      } catch (error) {
        importL();
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  useEffect(() => {
    document.addEventListener("trueid_result", async function (e: any) {
      const scanData = e.detail;
      getAccountToken();
      Log("scannn dâta", scanData);
      // check & do action
      if (!!scanData) {
        const {
          person,
          backCardImage,
          frontCardImage,
          result: {
            kyc_result: { front, decision },
          },
        } = scanData;
        var obj = {};
        if (front?.[`id_type`]?.[`type`] == 8) {
          obj = await convertDaTaNewCC(scanData);
        } else {
          obj = await convertDaTaCCCD(scanData);
        }
        if (scanData?.cardInfo?.result?.kyc_result?.decision?.code == -1) {
          Alert.showError({
            content: `alert.ekycfail`,
            onPress: () => {
              if (countTime.current >= 3) {
                navigate("LoginScreen");
                return;
              }
              gotoEKYC();
              countTime.current += 1;
            },
          });
          return;
        }
        const idNo = await getStoreData("idNo");
        const phone = await getStoreData("phone");
        if (!!idNo)
          if (
            (!!idNo && idNo != person?.idNumber) ||
            (!!phone && phone != route.params?.data?.phone)
          ) {
            Alert.showError({
              content: `Số giấy tờ tùy thân đăng ký tại Ngân hàng đang khác với số giấy tờ tùy thân sử dụng đăng ký mở tài khoản. Quý khách vui lòng kiểm tra lại`,
              multilanguage: false,
              type: 2,
              onClose: () => {
                PostMSG("CreateAccountFailure", {});
              },
              onPress: () => {
                PostMSG("CreateAccountFailure", {});
              },
              onCancel: () => {
                PostMSG("CreateAccountFailure", {});
              },
              onConfirm: () => {
                PostMSG("CreateAccountFailure", {});
              },
            });
            return;
          }

        navigate("ReviewInfoModal", {
          data: {
            ...obj,
            currentUser: {
              ...currentUser,
              ...(route.params?.data?.phone && {
                phone: route.params?.data?.phone,
              }),
              ...(route.params?.data?.password && {
                password: route.params?.data?.password,
              }),
              ...(route.params?.data?.name && {
                name: route.params?.data?.name,
              }),
              ...(route.params?.data?.email && {
                email: route.params?.data?.email,
              }),
            },
          },
        });
        return;
      }
      //
    });
    return () => {};
  }, []);

  const getAccountToken = async () => {
    try {
      setLoading(true);
      const res = await apiAuth.login({
        username: route.params?.data?.phone,
        password: route.params?.data?.password,
      });
      if (res.status == 200) {
        await setStoreToken(res.data.access_token);
        await setStoreData("mToken", res.data.access_token);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const gotoEKYC = async () => {
    // navigate("ReviewInfoModal", {
    //   data: {},
    // });
    // // return;
    // test();
    // return;
    const res = await apiAuth.login({
      username: route.params?.data?.phone,
      password: route.params?.data?.password,
    });
    if (res.status == 200) {
      await setStoreToken(res.data.access_token);
      await setStoreData("mToken", res.data.access_token);
      var event = new CustomEvent("start", {
        detail: {
          config: {
            domain: `${urlApp.APIURL}api`,
            domainPath: "/ekyc/v1.2",
            authDomain: "https://api.trueid.ai",
            authDomainPath: "/v1/oauth",
            appId: "ea8df9ebff8d38479058d7f1d235e097",
            appSecret: "+xzqA0O4GScV2dSiaB2cDiYVDY7hE0pG6rqN0TTNbU4=",
            lang: "vi",
            accessToken: res.data.access_token,
            theme: {
              backgroundColor: "#ffffff",
              borderColor: "#efe8df",
              errorColor: "#e87722",
              primaryColor: "#ed1c24",
              secondaryColor: "#efe8df",
              textColor: "#231f20",
            },
          },
        },
      });
      document.dispatchEvent(event);
    }
  };

  const test = () => {
    var event = new CustomEvent("start", {
      detail: {
        config: {
          domain: `${urlApp.APIURL}api`,
          domainPath: "/ekyc/v1.2",
          authDomain: "https://api.trueid.ai",
          authDomainPath: "/v1/oauth",
          appId: "ea8df9ebff8d38479058d7f1d235e097",
          appSecret: "+xzqA0O4GScV2dSiaB2cDiYVDY7hE0pG6rqN0TTNbU4=",
          lang: "vi",
          // accessToken: res.data.access_token,
          accessToken:
            "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwOTg3NjY2MjMyIiwiUk9MRVMiOlsiUk9MRV9JTlZFU1RPUiJdLCJleHAiOjE3MDQ5NzYzNzksImlhdCI6MTcwNDk1ODM3OX0._291X03IFiHMkGX4R1_RTrE8YTMSj-f_OaG3ITNOMVdiBHkYdxKrd8YERGpc4VIHysLcwLrLyUMSQ9NeNNO22Q",
          theme: {
            backgroundColor: "#ffffff",
            borderColor: "#efe8df",
            errorColor: "#e87722",
            primaryColor: "#ed1c24",
            secondaryColor: "#efe8df",
            textColor: "#231f20",
          },
        },
      },
    });
    document.dispatchEvent(event);
  };

  return (
    <Div screen={true}>
      <HeaderBack
        isShowStep={true}
        step={3}
        type={4}
        onGoBack={() => {
          navigate("LoginScreen");
        }}
        title={`setpasswordscreen.titletrong`}
      />
      <Div paddingHorizontal={29}>
        <Div width={"100%"} alignItems={"center"}>
          <Label
            marginTop={30}
            textAlign={"center"}
            size={20}
            fontWeight={"700"}
          >
            {`controlekyc.xacthucthongtin`}
          </Label>
          <Label marginTop={6} textAlign={"center"} size={14} lineHeight={20}>
            {`controlekyc.content`}
          </Label>
        </Div>
        <Label marginTop={40} size={14} lineHeight={20}>
          {`controlekyc.chuphinhmattruoc`}
        </Label>
        <Div
          flexDirection={"row"}
          marginTop={17}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <ImageView
            width={132}
            height={100}
            resizeMode={"contain"}
            source={Icons.before}
            marginRight={11}
          />
          <ImageView
            width={132}
            height={100}
            resizeMode={"contain"}
            source={Icons.after}
          />
          <ImageView
            height={1}
            resizeMode={"contain"}
            width={1}
            source={Icons.a}
          />
          <ImageView
            height={1}
            resizeMode={"contain"}
            width={1}
            source={Icons.closeee}
          />
          <ImageView
            height={1}
            resizeMode={"contain"}
            width={1}
            source={Icons.checked}
          />
          <ImageView
            height={1}
            resizeMode={"contain"}
            width={1}
            source={Icons.iconid}
          />
          <ImageView
            height={1}
            resizeMode={"contain"}
            width={132}
            source={Icons.id_card}
          />
          <ImageView
            height={1}
            resizeMode={"contain"}
            width={132}
            source={Icons.f}
          />
        </Div>
        <Label marginTop={18} size={14} lineHeight={20}>
          {`controlekyc.chuphinhchandung`}
        </Label>
        <Div>
          <ImageView
            height={100}
            marginTop={17}
            width={132}
            source={Icons.cmnd}
            resizeMode={"contain"}
          />
        </Div>
      </Div>
      <Div alignItems={"center"}>
        <ButtonBorder
          loading={loading}
          onPress={() => {
            gotoEKYC();
          }}
          marginTop={30}
          type={1}
          title={"controlekyc.batdau"}
        />
      </Div>
      <View nativeID={"trueidsdk"} />
    </Div>
  );
  return (
    <Div screen={true}>
      <HeaderBack type={4} title={`Đăng nhập`} />

      <Div paddingHorizontal={29}>
        <Label marginTop={30} size={20} fontWeight={"700"}>
          {`Xác thực thông tin`}
        </Label>
        <Label marginTop={6} size={14} lineHeight={20}>
          {`Để hoàn tất việc mở tài khoản trên FPlatform, vui lòng thực hiện các bước xác thực thông tin dưới đây`}
        </Label>
        <Label marginTop={40} size={14} lineHeight={20}>
          {`Chụp hình CMND/CCCD`}
        </Label>
        <Div
          flexDirection={"row"}
          marginTop={17}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <ImageView
            width={132}
            height={90}
            resizeMode={"contain"}
            source={Icons.before}
            marginRight={11}
          />
          <ImageView
            resizeMode={"contain"}
            height={90}
            width={132}
            source={Icons.after}
          />
        </Div>

        <Label marginTop={18} size={14} lineHeight={20}>
          {`Chụp hình chân dung`}
        </Label>
        <Div>
          <ImageView
            marginTop={17}
            width={132}
            height={90}
            source={Icons.cmnd}
            resizeMode={"contain"}
          />
        </Div>
      </Div>
      <Div marginTop={20} alignItems={"center"}>
        <View nativeID={"trueidsdk"} />
        <ButtonBorder
          loading={loading}
          onPress={async () => {
            // const r = await doGetAxios(`user/investment/load`);
            // Log("rrr", r);
            // return;
            // Log(hashStringMd5(`1521572420377588986RR0377588986`));
            // Log("123123");
            // 'idNo: 152157242' \
            // --header 'Phone: 0377588986' \
            // --header 'refCode: RR0377588986' \
            var event = new CustomEvent("start", {
              detail: {
                config: {
                  domain: "https://api.fma.dev.techland.link/web",
                  domainPath: "/ekyc/v1.2",
                  authDomain: "https://sdk-stag.trueid.ai/auth",
                  authDomainPath: "/v1/oauth",
                  appId: "7c55d934f47f19371220aaa38b5d2a4c",
                  appSecret: "dIuYznA6cfoDixqHolokUOq5f32gW0XXSsHTVWKBMvk=",
                  language: "vi",
                  accessToken:
                    "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpc3NhY2VAZmluY29ycC5jb20iLCJhdWRpZW5jZSI6IndlYiIsImNyZWF0ZWQiOjE2MzgzNDc0MjU3MzQsInVzZXJ0eXBlIjoiSU5WRVNUT1IiLCJleHAiOjE2MzgzNzYyMjV9.BqM34QpiWUIkVheBD0RMYCioDWPOZzcDCbM_l83fgzC8zZshq61Ey2eWCUWgs8D9licxk_Tn4dmWS9zEhVLyvA",
                },
              },
            });
            document.dispatchEvent(event);
          }}
          marginTop={30}
          type={1}
          title={"Bắt đầu"}
        />
      </Div>
    </Div>
  );
}

export default React.memo(ControlEKYCScreen);
