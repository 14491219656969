export enum urlApp {
  //////////////test -------------------------------------------
  APIURL = "https://api.mio.dev.techland.link/web/v1/", //test
  APIURLV3 = "https://api.mio.dev.techland.link/web/v3/", //test
  keyhash = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAngAMlGYnVnC4Bixgq0z0eLQ0zOAXkOYk67jnEw+c7Pho9FaEDWI3fq5wUMvh91GOCP3hWfB7dVTrXynf5nismF1XVhe9CX0O+prw+O/ixKVC/24QmdA0XJf7XXdcHizajOdXZNwrF1P00qp+ZhMi3AyVhsHONCRcLTKNhRxUlcPpdUZw2I2gaY+x9R4c1ZeKV7kW1y9TMJj4Cu1UpYQZYmR3pGQ5ytESD9ACHzsdz41OGa7zJ8/bq+xw1YM+dlEt9tgGeC+/UXw59yjCy65LQl73tc2jf+iK7p4idkknUXDgyPzigoMeyXUXRksQO9iQvYV1KDpfbVtqDZhiuLXgrwIDAQAB",
  /////////  test,

  // // uat -------------------------------------------
  // APIURL = "https://apis.mio-uat.vinacapital.com/web/v1/", //uat
  // APIURLV3 = "https://apis.mio-uat.vinacapital.com/web/v3/", //uat
  // keyhash = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwFOfHkxP1g81Q08tUp8kP6tTW/AH79w+RXn34C8mVGB2zpJ2ieSouihJVKXMIHOpWUmRlrh0Uwwp0QW+L0qMAKo7wINu26bzE/U9RoojFO67jiooEjUrtYOmqEbxuozVBDauZvpAoFf97SU8jJ+64ps1u7LzUEm0GXr5M2nK/lEGdBIS7/8C0SqCOTnghS8VDdBNVDf0FyMvPArT03O7vejcsOILN7yhCvYqjhJgCKluiAEir2k7Fe1PFFrR2trZWXz7UZvjEADnz4aNUYEJatWKQyn7Q9DuMDR0gcuoMWe3gk7YT1FMOG+Eufpz1+N/nIpf/KHygha7Keoheq5KrwIDAQAB",

  // // live -------------------------------------------
  // APIURL = "https://apis.mio.vinacapital.com/web/v1/", //that
  // APIURLV3 = "https://apis.mio.vinacapital.com/web/v3/", //that
  // keyhash = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAswid5Izra0ZcE14jxF/0wrio88YhJ4iN17//h3p8n+hjA1JyGEgf85MSUYavsBjuevmJKh7GerVghqW4fcAR1coK43J6GuF1r7ZZHorGJW/fZrq9f/msJKDgH3wjjR7of6MiNxk2eEp9axjLp1ZY23LwxbNGk/ztwg8fQtDllEarxO4V/RPV/2FNgFDyznHpPqg85//fgpCHUk+vjJqwBUGl/zwtO83KJvvlQjOC0f9dNzee3dVQk9kezSpxbMeFjm+v/FqwREAqUjBXksYW0lILGisLPxYH+GZrHhUqy1z2WDNY3ffSbIZ389QcJXcQWOtHL4628xWEpQS7agyzVwIDAQAB",
}
